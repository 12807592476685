import {Component, OnInit} from '@angular/core';
import {AbstractControl, AbstractControlOptions, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";

// validators
import {ConfirmedValidator} from "../../shared/validators/confirmed.validator";

// services
import {AuthService} from "../../shared/services/auth.service";
import {SnackbarService} from "../../shared/services/snackbar.service";

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss', './../auth.component.scss']
})
export class RegisterComponent implements OnInit {

    public registerForm!: FormGroup;

    public userNameMinLength = 3;
    public userNameMaxLength = 15;
    public companyNameMinLength = 3;
    public companyNameMaxLength = 100;
    public emailMaxLength = 100;
    public passwordMinLength = 8;
    public passwordMaxLength = 40;

    constructor(private formBuilder: FormBuilder,
                private authService: AuthService,
                private snackbarService: SnackbarService,
                private router: Router) {
    }

    public ngOnInit(): void {
        this.buildRegisterForm();
    }

    public get name(): AbstractControl {
        return this.registerForm.controls['name'];
    }

    public get company_name(): AbstractControl {
        return this.registerForm.controls['company_name'];
    }

    public get email(): AbstractControl {
        return this.registerForm.controls['email'];
    }

    public get password(): AbstractControl {
        return this.registerForm.controls['password'];
    }

    public get password_confirmation(): AbstractControl {
        return this.registerForm.controls['password_confirmation'];
    }

    public register(): void {
        this.authService.register(this.registerForm.getRawValue()).subscribe({
            next: (): void => {
                this.router.navigate(['/auth/login']).then()
            },
            error: () => this.snackbarService.snackbarCustomError('The email has already been taken.'),
            complete: (): void => {
                this.snackbarService.snackbarCustomComplete('Congratulations, your account has been created, you can login!');
            }
        });
    }

    private buildRegisterForm(): void {
        this.registerForm = this.formBuilder.group({
                name: ['', {
                    validators: [Validators.required,
                        Validators.minLength(this.userNameMinLength), Validators.maxLength(this.userNameMaxLength)]
                }],
                company_name: ['', {
                    validators: [Validators.required,
                        Validators.minLength(this.companyNameMinLength), Validators.maxLength(this.companyNameMaxLength)]
                }],
                email: ['', {
                    validators: [Validators.required,
                        Validators.email, Validators.maxLength(this.emailMaxLength)]
                }],
                password: ['', {
                    validators: [Validators.required,
                        Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)]
                }],
                password_confirmation: ['', {validators: [Validators.required]}],
                regulations: [false, Validators.pattern('true')],
            },
            {validator: ConfirmedValidator('password', 'password_confirmation')} as AbstractControlOptions);
    }
}
