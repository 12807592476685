<div class="wrapper">
    <mat-card class="form-card text-center bg-white mat-elevation-z3">
        <form [formGroup]="loginForm">
            <app-auth-top [title]="'Welcome'"
                          [subTitle]="'Login to your account'"></app-auth-top>
            <mat-card-content>
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>e-mail</mat-label>
                    <input matInput formControlName="email" name="email" type="email" placeholder="e-mail" #email>
                    <mat-hint align="end">
                        {{ email.value.length }} / {{ emailMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="emailAddress.errors?.['email'] || emailAddress.errors?.['required']">
                        Please enter a valid email address.
                    </mat-error>
                    <mat-error *ngIf="email.value.length > emailMaxLength && !emailAddress.errors?.['email']">
                        A maximum length of email address is {{ emailMaxLength }} characters.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width pt-2" appearance="outline">
                    <mat-label>password</mat-label>
                    <input matInput formControlName="password" name="password" type="password" placeholder="password"
                           #passwd>
                    <mat-hint align="end">
                        {{ passwd.value.length }} / {{ passwordMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="password.errors?.['required']">
                        Please enter a password.
                    </mat-error>
                    <mat-error *ngIf="passwd.value.length < passwordMinLength && !password.errors?.['required']">
                        A minimum length of password is {{ passwordMinLength }} characters.
                    </mat-error>
                    <mat-error *ngIf="passwd.value.length > passwordMaxLength">
                        A maximum length of password is {{ passwordMaxLength }} characters.
                    </mat-error>
                </mat-form-field>
                <div class="pt-2">
                    <button type="button" class="full-width" mat-stroked-button color="primary"
                            matTooltip="Login to app" matTooltipPosition="above" (click)="login()">
                        Login <i class="fa-solid fa-right-to-bracket"></i></button>
                </div>
            </mat-card-content>
            <div class="text-center py-4">
                <a class="link-secondary" routerLink="/auth/password"
                   matTooltip="Link to forgot password page" matTooltipPosition="right">Forgot password?</a>
                <div class="pt-2">
                    <span>If you don't have an account, please </span>
                    <a class="link-secondary" routerLink="/auth/register"
                       matTooltip="Link to register page" matTooltipPosition="right">register</a><span>.</span>
                </div>
            </div>
        </form>
    </mat-card>
    <div class="mt-2">
        <app-company-link></app-company-link>
    </div>
</div>
