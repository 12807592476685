<div *ngIf="isLoading; else content">
    <div class="wrapper">
        <mat-spinner></mat-spinner>
    </div>
</div>
<ng-template #content>
    <div class="wrapper">
        <mat-card class="form-card text-center bg-white mat-elevation-z3">
            <form [formGroup]="passwordResetForm">
                <app-auth-top [title]="'Password change'"
                              [subTitle]="'Fill out the form to change your account password.'"
                              [closeLink]="'/auth/login'"></app-auth-top>
                <mat-card-content>
                    <mat-form-field class="full-width pt-1" appearance="outline">
                        <mat-label>e-mail</mat-label>
                        <input matInput [formControl]="emailFormControl">
                    </mat-form-field>
                    <mat-form-field class="full-width pt-1" appearance="outline">
                        <mat-label>password</mat-label>
                        <input matInput formControlName="password" name="password" type="password"
                               placeholder="password" #passwd>
                        <mat-hint align="end">
                            {{ passwd.value.length }} / {{ passwordMaxLength }}
                        </mat-hint>
                        <mat-error *ngIf="password.errors?.['required']">
                            Please enter a password.
                        </mat-error>
                        <mat-error *ngIf="passwd.value.length < passwordMinLength && !password.errors?.['required']">
                            A minimum length of password is {{ passwordMinLength }} characters.
                        </mat-error>
                        <mat-error *ngIf="passwd.value.length > passwordMaxLength">
                            A maximum length of password is {{ passwordMaxLength }} characters.
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="full-width pt-1" appearance="outline">
                        <mat-label>password confirm</mat-label>
                        <input matInput formControlName="password_confirmation" name="password_confirmation"
                               type="password" placeholder="password confirm">
                        <mat-error *ngIf="password_confirmation.errors?.['required']">
                            Please enter a password confirm.
                        </mat-error>
                        <mat-error
                            *ngIf="password_confirmation.errors?.['confirmedValidator'] || password_confirmation.errors?.['touched']">
                            Password and password confirm must be match.
                        </mat-error>
                    </mat-form-field>
                    <div class="pt-3">
                        <button type="button" class="full-width" mat-stroked-button color="primary"
                                [disabled]="passwordResetForm.invalid"
                                matTooltip="Save new password button" matTooltipPosition="above"
                                (click)="passwordReset()">
                            Save Password <i class="fa-solid fa-floppy-disk"></i></button>
                    </div>
                </mat-card-content>
                <div class="py-4">
                    <span>If you don't want reset password go back to </span>
                    <a class="link-secondary" routerLink="/auth/login"
                       matTooltip="Back to login page" matTooltipPosition="right">login</a><span>.</span>
                </div>
            </form>
        </mat-card>
        <div class="mt-2">
            <app-company-link></app-company-link>
        </div>
    </div>
</ng-template>
