import {Component, OnInit} from '@angular/core';
import {AbstractControl, AbstractControlOptions, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";

// services
import {AuthService} from "../../shared/services/auth.service";
import {SnackbarService} from "../../shared/services/snackbar.service";

// models
import {ApiMessage} from "../../shared/models/api-message";

// validators
import {ConfirmedValidator} from "../../shared/validators/confirmed.validator";

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss', './../auth.component.scss']
})
export class PasswordResetComponent implements OnInit {

    public passwordResetForm!: FormGroup;
    public emailFormControl: FormControl = new FormControl({value: '', disabled: true});

    public emailMaxLength = 100;
    public passwordMinLength = 8;
    public passwordMaxLength = 40;

    public isLoading = false;

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private authService: AuthService,
                private router: Router,
                private snackbarService: SnackbarService) {
    }

    public ngOnInit(): void {
        this.buildPasswordResetForm();
    }

    public get email(): AbstractControl {
        return this.passwordResetForm.controls['email'];
    }

    public get password(): AbstractControl {
        return this.passwordResetForm.controls['password'];
    }

    public get password_confirmation(): AbstractControl {
        return this.passwordResetForm.controls['password_confirmation'];
    }

    public passwordReset(): void {
        this.isLoading = true;
        this.authService.setNewPassword(this.passwordResetForm.getRawValue()).subscribe((message: ApiMessage): void => {
            if (message.message == 'Wrong token') {
                this.isLoading = false;
                this.snackbarService.snackbarCustomError('Invalid token or token expire. Please send new password reset request.');
            }
            if (message.message == 'Password has been changed.') {
                this.isLoading = false;
                this.snackbarService.snackbarCustomComplete(message.message);
                this.router.navigate(['/auth/login']).then();
            }
        });
    }

    private buildPasswordResetForm(): void {
        const token: string | null = this.route.snapshot.queryParamMap.get('token');
        const email: string | null = this.route.snapshot.queryParamMap.get('email');
        this.emailFormControl = new FormControl({value: email, disabled: true});
        this.passwordResetForm = this.formBuilder.group({
                token: [token, {validators: [Validators.required]}],
                email: [email, {
                    validators: [Validators.required,
                        Validators.email, Validators.maxLength(this.emailMaxLength)]
                }],
                password: ['', {
                    validators: [Validators.required,
                        Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)]
                }],
                password_confirmation: ['', {validators: [Validators.required]}],
            },
            {validator: ConfirmedValidator('password', 'password_confirmation')} as AbstractControlOptions);
    }
}
