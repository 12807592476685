<div class="wrapper">
    <mat-card class="form-card text-center bg-white mat-elevation-z3">
        <form [formGroup]="registerForm">
            <app-auth-top [title]="'Register'"
                          [subTitle]="'Fill out the form to register your account'"
                          [closeLink]="'/auth/login'"></app-auth-top>
            <mat-card-content>
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Your name or nick name</mat-label>
                    <input matInput formControlName="name" name="name" type="text" placeholder="Name or nick name"
                           #displayName>
                    <mat-hint align="end">
                        {{ displayName.value.length }} / {{ userNameMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="name.errors?.['required']">
                        Please enter a name or nick name.
                    </mat-error>
                    <mat-error *ngIf="name.value.length < userNameMinLength && !name.errors?.['required']">
                        A minimum length of name is {{ userNameMinLength }} characters.
                    </mat-error>
                    <mat-error *ngIf="name.value.length > userNameMaxLength">
                        A maximum length of name is {{ userNameMaxLength }} characters.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Company name</mat-label>
                    <input matInput formControlName="company_name" name="company_name" type="text"
                           placeholder="Company name"
                           #companyName>
                    <mat-hint align="end">
                        {{ companyName.value.length }} / {{ companyNameMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="company_name.errors?.['required']">
                        Please enter a name or nick name.
                    </mat-error>
                    <mat-error
                        *ngIf="company_name.value.length < companyNameMinLength && !company_name.errors?.['required']">
                        A minimum length of the company name is {{ companyNameMinLength }} characters.
                    </mat-error>
                    <mat-error *ngIf="company_name.value.length > companyNameMaxLength">
                        A maximum length of the company name is {{ companyNameMaxLength }} characters.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width pt-1" appearance="outline">
                    <mat-label>e-mail</mat-label>
                    <input matInput formControlName="email" name="email" type="email" placeholder="e-mail"
                           #emailAddress>
                    <mat-hint align="end">
                        {{ emailAddress.value.length }} / {{ emailMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="email.errors?.['email'] || email.errors?.['required']">
                        Please enter a valid email address.
                    </mat-error>
                    <mat-error *ngIf="emailAddress.value.length > emailMaxLength && !email.errors?.['email']">
                        A maximum length of email address is {{ emailMaxLength }} characters.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width pt-1" appearance="outline">
                    <mat-label>password</mat-label>
                    <input matInput formControlName="password" name="password" type="password" placeholder="password"
                           #passwd>
                    <mat-hint align="end">
                        {{ passwd.value.length }} / {{ passwordMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="password.errors?.['required']">
                        Please enter a password.
                    </mat-error>
                    <mat-error *ngIf="passwd.value.length < passwordMinLength && !password.errors?.['required']">
                        A minimum length of password is {{ passwordMinLength }} characters.
                    </mat-error>
                    <mat-error *ngIf="passwd.value.length > passwordMaxLength">
                        A maximum length of password is {{ passwordMaxLength }} characters.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width pt-1" appearance="outline">
                    <mat-label>password confirm</mat-label>
                    <input matInput formControlName="password_confirmation" name="password_confirmation" type="password"
                           placeholder="password confirm">
                    <mat-error *ngIf="password_confirmation.errors?.['required']">
                        Please enter a password confirm.
                    </mat-error>
                    <mat-error
                        *ngIf="password_confirmation.errors?.['confirmedValidator'] || password_confirmation.errors?.['touched']">
                        Password and password confirm must be match.
                    </mat-error>
                </mat-form-field>
                <mat-checkbox formControlName="regulations" color="primary">
                    I read and accept the <a class="link-secondary" routerLink="/regulations"
                                             matTooltip="Link to regulations page" matTooltipPosition="right">regulations</a>
                </mat-checkbox>
                <div class="pt-3">
                    <button type="button" class="full-width" mat-stroked-button color="primary"
                            [disabled]="registerForm.invalid"
                            matTooltip="Register shop account" matTooltipPosition="above" (click)="register()">
                        Register <i class="fa-solid fa-pen-to-square"></i></button>
                </div>
            </mat-card-content>
            <div class="py-4">
                <span>If you have an account, please </span>
                <a class="link-secondary" routerLink="/auth/login"
                   matTooltip="Back to login page" matTooltipPosition="right">login</a><span>.</span>
            </div>
        </form>
    </mat-card>
    <div class="mt-2">
        <app-company-link></app-company-link>
    </div>
</div>
